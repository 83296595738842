import axios, { AxiosStatic } from "axios";
import { backendUrl } from "../environment/environment";
import { getLocalMomentDate } from "../types/utils";

export class ApiClient {
  constructor(private _client: AxiosStatic = axios) {
    axios.interceptors.response.use(
      (response: any) => response,
      (error: { response: { status: number } }) => {
        if (!error.response || error?.response?.status === 500) {
          alert("Ошибка сервера");
        }
        if (error?.response?.status === 401) {
          alert("Ваша сессия истекла, авторизуйтесь ещё раз.");
          localStorage.removeItem("authToken");
          localStorage.removeItem("companyTimeZone");
          window.location.replace("/");
        }
        return Promise.reject(error);
      }
    );
    
    const localStorageURL = localStorage.getItem('backendUrl');
    if(localStorageURL){
      _client.defaults.baseURL = localStorageURL;
    }else{
    _client.defaults.baseURL = backendUrl;
  }}

  setBackendUrl(url:string){
    this._client.defaults.baseURL=url;
  }

  setAuthToken(token: string): void {
    this._client.defaults.headers.common["Authorization"] = `${token}`;
  }

  authUser(): Promise<any> {
    return axios
      .post(`${axios.defaults.baseURL}/api/abonent/main/data`, {})
      .then((res: any) => {
        return res.data.data;
      });
  }
  getMessages(
    startDate: any,
    stopDate: any,
    msgTypes: number[],
    accountingPointId: string
  ): Promise<any> {
    return axios
      .post(`${axios.defaults.baseURL}/api/abonent/point/messages`, {
        startDate: getLocalMomentDate(
          stopDate.utc().startOf("day").unix(),
          true
        )
          .utc()
          .unix(),
        stopDate: getLocalMomentDate(startDate.utc().endOf("day").unix(), true)
          .utc()
          .unix(),
        msgType: msgTypes,
        accounting_point_id: accountingPointId,
      })
      .then((res: any) => {
        return res.data.data.messages.data;
      });
  }
  putManualMessage(body: any): Promise<any> {
    return axios
      .put(`${axios.defaults.baseURL}/api/abonent/point/messages`, body)
      .then((res: any) => {
        return res.data.data;
      });
  }
}

export const apiClient: ApiClient = new ApiClient();
