import React, { useEffect, useState } from "react";
import {
  putManualMessageAction,
  selectDevicesList,
} from "../../../store/reducers/app.reducer";
import { Button, Input, Modal, Result, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

export const DeliveryMessageModal = ({
  visible,
  setVisible,
  point,
  setSelectedPoint,
}: {
  visible: boolean;
  setVisible: Function;
  point: any;
  setSelectedPoint: Function;
}) => {
  const dispatch = useDispatch();
  const [currentDevice, setCurrentDevice] = useState<any>(null);
  const [messageField, setMessageField] = useState<string[]>([]);
  const [resultMessage, setResultMessage] = useState({});
  const [result, setResult] = useState<string>();
  const [error, setError] = useState("");

  const devicesList = useSelector(selectDevicesList);

  useEffect(() => {
    setCurrentDevice(_.findLast(devicesList, { id: point?.device_id }));
  }, [point]);

  useEffect(() => {
    return () => {
      handleCancel();
    };
  }, []);

  useEffect(() => {
    if (currentDevice !== null) {
      if (
        currentDevice?.device_group_id === 4 &&
        point.tariff_type !== null &&
        point.tariff_type > 1
      ) {
        const tmpFields: string[] = [];
        for (let i = 1; i <= point.tariff_type; i++) {
          tmpFields.push(`tariff${i}`);
        }
        setMessageField(tmpFields);
      } else if (
        currentDevice?.device_group_id === 1 ||
        currentDevice?.device_group_id === 2
      ) {
        setMessageField(["in1"]);
      } else {
        setMessageField(["tariff1"]);
      }
    }
  }, [currentDevice]);

  const handleCancel = () => {
    setVisible(false);
    setTimeout(() => {
      setSelectedPoint(null);
      setResultMessage({});
      setMessageField([]);
      setCurrentDevice(null);
      setResult(undefined);
      setError("");
    }, 100);
  };
  const onSubmit = () => {
    const body: any = { consumer_messages: {} };
    body["consumer_messages"][point._id] = resultMessage;
    dispatch(putManualMessageAction(body, setResult, setError));
  };
  return (
    <Modal
      width={!result ? "300px" : "600px"}
      title={`Подача показаний по ТУ ${point?.name}`}
      visible={visible}
      onCancel={handleCancel}
      footer={[]}
    >
      {currentDevice && !result && (
        <div className={"deliver-message--form"}>
          <Space direction="vertical">
            {messageField.map((field: string, index: number) => (
              <Input
                onChange={(e) => {
                  const tmpM: any = {};
                  tmpM[e.target.name] = !isNaN(parseFloat(e.target.value))
                    ? parseFloat(e.target.value)
                    : undefined;
                  setResultMessage((prevState) => ({ ...prevState, ...tmpM }));
                }}
                name={field}
                addonBefore={
                  currentDevice?.device_group_id === 4
                    ? `Тариф ${index === 0 ? "общий" : index}`
                    : "Показания"
                }
                defaultValue=""
                type={"number"}
              />
            ))}
            <Button type="primary" size={"large"} onClick={onSubmit}>
              Сохранить показания
            </Button>
          </Space>
        </div>
      )}
      {result === "success" && (
        <Result
          status="success"
          title={`Показания по ТУ ${point?.name} успешно сохранены!`}
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => handleCancel()}
            >
              Закрыть
            </Button>,
          ]}
        />
      )}
      {result === "error" && (
        <Result
          status="error"
          title={`При сохранении показаний произошла ошибка`}
          subTitle={error}
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => handleCancel()}
            >
              Закрыть
            </Button>,
            <Button key="buy" onClick={() => setResult(undefined)}>
              Попробовать ещё раз
            </Button>,
          ]}
        />
      )}
    </Modal>
  );
};
