import moment from "moment";

const crypto = require("crypto");
const ENC_KEY = "9F86D081884C7D659A2FEAA0C55AD015";
const IV = "206DFC4E0335FA0A";

export const encrypt = (val: any) => {
  let cipher = crypto.createCipheriv("aes-256-cbc", ENC_KEY, IV);
  let encrypted = cipher.update(val, "utf8", "base64");
  encrypted += cipher.final("base64");
  return encrypted;
};

export const decrypt = (encrypted: any) => {
  let decipher = crypto.createDecipheriv("aes-256-cbc", ENC_KEY, IV);
  let decrypted = decipher.update(encrypted, "base64", "utf8");
  return decrypted + decipher.final("utf8");
};

export const getLocalMomentDate = (unix: number, toUtc = false) => {
  const currentCompanyTimezone =
    (localStorage.getItem("companyTimeZone") &&
      parseInt(localStorage.getItem("companyTimeZone") as string)) ||
    0;
  return toUtc ? moment.unix(unix - 60 * 60 * currentCompanyTimezone).utc() : moment.unix(unix + 60 * 60 * currentCompanyTimezone).utc();
};
