export const loginFormValidation = (values: any) => {
  const errors: any = {};
  if (!values.login) {
    errors.login = "Введите логин";
  } else if (!values.password) {
    errors.password = "Введите пароль";
  }else if (!values.server) {
    errors.server = "Введите адрес сервера";
  }
  return errors;
};
