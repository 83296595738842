import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { apiClient } from "../../services/api-client";
import { keyBy } from "lodash";

export interface AppState {
  accountingPointList: any[];
  suppliersList: any[];
  objectsTree: any[];
  devicesList: any[];
  deviceMessages: any;
  isLoading: boolean;
}

export const app = createSlice({
  name: "app",
  initialState: {
    accountingPointList: [],
    suppliersList: [],
    objectsTree: [],
    devicesList: [],
    deviceMessages: null,
    isLoading: true,
  },
  reducers: {
    setAccountingPointList: (state, action): void => {
      state.accountingPointList = action.payload;
    },
    setSuppliersList: (state, action): void => {
      state.suppliersList = action.payload;
    },
    setObjectsTree: (state, action): void => {
      state.objectsTree = action.payload;
    },
    setDevicesList: (state, action): void => {
      state.devicesList = action.payload;
    },
    setDevicesMessages: (state, action): void => {
      state.deviceMessages = action.payload;
    },
    setLoading: (state, action): void => {
      state.isLoading = action.payload;
    },
  },
});

/*******************************************/
export const {
  setAccountingPointList,
  setSuppliersList,
  setObjectsTree,
  setDevicesList,
  setDevicesMessages,
  setLoading,
} = app.actions;
export const setAccountingPointListAction = (pointsList: any[]) => (
  dispatch: any
) => {
  dispatch(setAccountingPointList(pointsList));
};
export const setSuppliersListAction = (suppliersList: any[]) => (
  dispatch: any
) => {
  dispatch(setSuppliersList(suppliersList));
};
export const setObjectsTreeAction = (objectsTree: any[]) => (dispatch: any) => {
  dispatch(setObjectsTree(objectsTree));
};
export const setDevicesListAction = (devicesList: any[]) => (dispatch: any) => {
  dispatch(setDevicesList(devicesList));
};
export const setIsLoading = (isLoading: boolean) => (dispatch: any) => {
  dispatch(setLoading(isLoading));
};
export const setDevicesMessagesAction = (devicesMessages: any) => (
  dispatch: any
) => {
  dispatch(setDevicesMessages(devicesMessages));
};
export const fetchDevicesMessagesAction = (
  startDate: any,
  endDate: any,
  msgTypes: number[],
  accountingPointId: string,
  callback?: Function
) => (dispatch: any) => {
  apiClient
    .getMessages(startDate, endDate, msgTypes, accountingPointId)
    .then((devicesMessages: any) => {
      dispatch(setDevicesMessages(devicesMessages));
      if (callback) {
        setTimeout(() => {
          callback();
        }, 1000);
      }
    });
};
export const putManualMessageAction = (
  body: any,
  callback: Function,
  setError: Function
) => (dispatch: any) => {
  apiClient.putManualMessage(body).then(
    (result) => {
      callback("success");
    },
    (error) => {
      callback("error");
      setError(error?.response?.data?.error?.data?.msg || "");
    }
  );
};
/*******************************************/
export const selectAccountingPointList = (state: RootState) => {
  return state.app.accountingPointList;
};
export const selectLoadingState = (state: RootState) => {
  return state.app.isLoading;
};
export const selectSuppliersList = (state: RootState) => {
  return state.app.suppliersList;
};
export const selectObjectsTree = (state: RootState) => {
  const result = [];
  for (let branchKey of Object.keys(state.app.objectsTree)) {
    // @ts-ignore
    result.push(...state.app.objectsTree[branchKey]);
  }
  return result;
};
export const selectDevicesList = (state: RootState) => {
  return state.app.devicesList;
};
export const selectCurrentDeviceMessages = (state: RootState) => {
  // return state.app.deviceMessages.map((i: {datetime:number}) => i.datetime);
  // const compare = (a, b) => (a>b) ? a : b;
  // const messages = state.app.deviceMessages.map((i: {datetime:number}) => i.datetime).sort(compare);
  
  return state.app.deviceMessages;

};
export default app.reducer;
