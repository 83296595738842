import React, { useEffect, useState } from "react";
import { Table } from "antd";
import _ from "lodash";
import moment from "moment";

const electroOneTariff = [
  {
    title: "Дата\\время",
    dataIndex: "datetime",
    key: "datetime",
  },
  {
    title: "Tариф общий",
    dataIndex: "tariff1",
    key: "tariff1",
  },
  {
    title: "Расход тариф общий",
    dataIndex: "delta_tariff1",
    key: "delta_tariff1",
  },
];

export const DeviceDataTable = ({
  point,
  messages,
}: {
  point: any;
  messages: any;
}) => {
  const [columns, setColumns] = useState<any>();
  const [dataSource, setDataSource] = useState<any>([]);

  useEffect(() => {
    const tmpMessages = _.cloneDeep(messages);
    tmpMessages.map((m: any, index: number) => {
        tmpMessages[index]['datetime'] = moment(tmpMessages[index]['datetime'] * 1000).format('DD.MM.YYYY HH:mm')
      if (index > 0) {
        if (point.resource_type_id === 3) {
          if (point?.tariff_type === 1 || !point?.tariff_type) {
            tmpMessages[index]["delta_tariff1"] =
              tmpMessages[index]["tariff1"] && tmpMessages[index - 1]["tariff1"]
                ? (tmpMessages[index]["tariff1"] -
                  tmpMessages[index - 1]["tariff1"]).toFixed(4)
                : "-";
            tmpMessages[index]["tariff1"] = tmpMessages[index]["tariff1"]
              ? tmpMessages[index]["tariff1"].toFixed(4)
              : "-";
          } else {
            for (let j = 0; j <= point?.tariff_type ; j++) {
              tmpMessages[index][`delta_tariff${j + 1}`] =
                tmpMessages[index][`tariff${j + 1}`] &&
                tmpMessages[index - 1][`tariff${j + 1}`]
                  ? (tmpMessages[index][`tariff${j + 1}`] -
                    tmpMessages[index - 1][`tariff${j + 1}`]).toFixed(4)
                  : "-";
              tmpMessages[index][`tariff${j + 1}`] = tmpMessages[index][
                `tariff${j + 1}`
              ]
                ? tmpMessages[index][`tariff${j + 1}`].toFixed(4)
                : "-";
            }
          }
        } else if (point.resource_type_id === 4) {
          tmpMessages[index]["delta_tariff1"] =
            tmpMessages[index]["tariff1"] && tmpMessages[index - 1]["tariff1"]
              ? (tmpMessages[index]["tariff1"] -
                tmpMessages[index - 1]["tariff1"]).toFixed(4)
              : "-";
          tmpMessages[index]["tariff1"] = tmpMessages[index]["tariff1"]
            ? tmpMessages[index]["tariff1"].toFixed(4)
            : "-";
        } else {
          tmpMessages[index]["delta_in1"] =
            tmpMessages[index]["in1"] && tmpMessages[index - 1]["in1"]
              ? (tmpMessages[index]["in1"] - tmpMessages[index - 1]["in1"]).toFixed(4)
              : "-";
          tmpMessages[index]["in1"] = tmpMessages[index]["in1"]
            ? tmpMessages[index]["in1"].toFixed(4)
            : "-";
        }
      }
    });
    tmpMessages.splice(0, 1);

    let sortedTmpMessages = tmpMessages;
    const compare:any = (a:any, b:any) => {
      const aDataTime = moment(a.datetime, 'DD.MM.YYYY HH:mm').unix();
      const bDataTime = moment(b.datetime, 'DD.MM.YYYY HH:mm').unix();
      return bDataTime - aDataTime;
    };
    sortedTmpMessages.sort(compare);

    setDataSource(sortedTmpMessages);
  }, [messages]);

  useEffect(() => {
    if (point.resource_type_id === 3) {
      if (point?.tariff_type === 1 || !point?.tariff_type) {
        setColumns(electroOneTariff);
      } else {
        const columns = [
          {
            title: "Дата\\время",
            dataIndex: "datetime",
            key: "datetime",
          },
        ];
        for (let j = 0; j <= point?.tariff_type; j++) {
          columns.push(
            {
              title: `Тариф ${j === 0 ? "общий" : j}`,
              dataIndex: `tariff${j + 1}`,
              key: `tariff${j + 1}`,
            },
            {
              title: `Расход тариф ${j === 0 ? "общий" : j}`,
              dataIndex: `delta_tariff${j + 1}`,
              key: `delta_tariff${j + 1}`,
            }
          );
        }
        setColumns(columns);
      }
    } else if (point.resource_type_id === 4) {
      setColumns([{
        title: "Дата\\время",
        dataIndex: "datetime",
        key: "datetime",
      },
        {
          title: "Показания",
          dataIndex: "tariff1",
          key: "tariff1",
        },
        {
          title: "Расход",
          dataIndex: "delta_tariff1",
          key: "delta_tariff1",
        },
      ]);
    } else {
      setColumns([
        {
          title: "Дата\\время",
          dataIndex: "datetime",
          key: "datetime",
        },
        {
          title: "Показания",
          dataIndex: "in1",
          key: "in1",
        },
        {
          title: "Расход",
          dataIndex: "delta_in1",
          key: "delta_in1",
        },
      ]);
    }
  }, [point]);

  return (
    <Table
      style={{ marginTop: "25px" }}
      locale={{ emptyText: "Нет данных" }}
      dataSource={dataSource}
      columns={columns}
    />
  );
};
