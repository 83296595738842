import React, { useEffect, useState } from "react";
import { ConfigProvider, DatePicker, Spin } from "antd";
import moment from "moment";
import "moment/locale/ru";
import locale from "antd/lib/locale/ru_RU";
import { useDispatch, useSelector } from "react-redux";
import ReactECharts from "echarts-for-react";
import {
  fetchDevicesMessagesAction,
  selectCurrentDeviceMessages,
  setDevicesMessagesAction,
} from "../store/reducers/app.reducer";
import _ from "lodash";
import { DeviceDataTable } from "./DeviceDataTable";

const { RangePicker } = DatePicker;

export function DeviceData(props: { accountingPoint: any; visible: boolean }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [datePeriodStart, setDatePeriodStart] = useState(moment());
  const [selectedMsgType] = useState(1);
  const [datePeriodEnd, setDatePeriodEnd] = useState(
    moment().subtract(1, "month")
  );
  const deviceMessages = useSelector(selectCurrentDeviceMessages);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      fetchDevicesMessagesAction(
        datePeriodStart,
        datePeriodEnd,
        [1],
        props.accountingPoint._id,
        () => {
          setIsLoading(false);
        }
      )
    );
    return () => {
      dispatch(setDevicesMessagesAction(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshData = (startDate: any, endDate: any) => {
    dispatch(setDevicesMessagesAction(null));
    dispatch(
      fetchDevicesMessagesAction(
        startDate,
        endDate,
        [1],
        props.accountingPoint._id
      )
    );
  };

  const getDatesFromMessages = () => {
    const datetimes = _.uniq(
      deviceMessages.map((m: { datetime: number }) =>
        moment.unix(m.datetime).utc().format("DD.MM.YYYY")
      )
    );
    datetimes.splice(0, 1);
    return datetimes;
  };

  const getDataForChart = () => {
    const messages = deviceMessages;
    let deltas: any =
      props.accountingPoint.resource_type_id === 3
        ? { tariff1: [], tariff2: [] }
        : props.accountingPoint.resource_type_id === 4
        ? { tariff1: [] }
        : { in1: [] };
    if (props.accountingPoint.resource_type_id === 3) {
      if (
        props.accountingPoint?.tariff_type === 1 ||
        !props.accountingPoint?.tariff_type
      ) {
        deltas = { tariff1: [] };
        for (let i = 0; i < messages.length; i++) {
          deltas.tariff1.push(messages[i].delta_tariff1.toFixed(4));
        }
      } else {
        deltas = {};
        for (let i = 0; i < messages.length; i++) {
          for (let j = 2; j <= props.accountingPoint?.tariff_type + 1; j++) {
            if (!deltas.hasOwnProperty(`tariff${j - 1}`)) {
              deltas[`tariff${j - 1}`] = [];
            }
            deltas[`tariff${j - 1}`].push(
              messages[i][`delta_tariff${j - 1}`]
                ? messages[i][`delta_tariff${j - 1}`].toFixed(4)
                : "-"
            );
          }
        }
      }
      const result = {
        series: Object.keys(deltas).map((dKey, index) => {
          deltas[dKey].splice(0, 1);
          return {
            name: `Тариф ${index === 0 ? "общий" : index + 1}`,
            type: "bar",
            barGap: 0,
            emphasis: {
              focus: "series",
            },
            data: deltas[dKey],
          };
        }),
      };
      return result;
    } else if (props.accountingPoint.resource_type_id === 4) {
      for (let i = 0; i < messages.length; i++) {
        deltas.tariff1.push(
          messages[i].delta_tariff1 && messages[i].delta_tariff1 !== "-"
            ? messages[i].delta_tariff1.toFixed(4)
            : "-"
        );
      }
      deltas.tariff1.splice(0, 1);
      return {
        series: [
          {
            name: "Показания",
            type: "bar",
            barGap: 0,
            emphasis: {
              focus: "series",
            },
            data: deltas.tariff1,
          },
        ],
        legend: {
          data: ["Показания"],
        },
      };
    } else {
      for (let i = 0; i < messages.length; i++) {
        deltas.in1.push(messages[i].delta_in1.toFixed(4));
      }
      deltas.in1.splice(0, 1);
      return {
        series: [
          {
            name: "Показания",
            type: "bar",
            barGap: 0,
            emphasis: {
              focus: "series",
            },
            data: deltas.in1,
          },
        ],
        legend: {
          data: ["Показания"],
        },
      };
    }
  };

  const getChartOptions = () => {
    const options = {
      grid: { top: 35, right: 8, bottom: 24, left: 36 },
      xAxis: {
        type: "category",
        data: getDatesFromMessages(),
      },
      yAxis: {
        type: "value",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
    };
    return { ...options, ...getDataForChart() };
  };

  return (
    <>
      <div className={"device-data--container"}>
        {isLoading ? (
          <Spin size={"large"} wrapperClassName={"spin--wrapper"} />
        ) : (
          <>
            <ConfigProvider locale={locale}>
              <div style={{ marginLeft: "50px" }}>
                Период:{" "}
                <RangePicker
                  style={{ marginLeft: "20px", marginTop: "25px" }}
                  onChange={(e: any) => {
                    if (e) {
                      setDatePeriodStart(e[1]);
                      setDatePeriodEnd(e[0]);
                      setTimeout(() => {
                        refreshData(e[1], e[0]);
                      }, 1000);
                    }
                  }}
                  defaultValue={[datePeriodStart, datePeriodEnd]}
                  format={"DD.MM.YYYY"}
                />
              </div>
            </ConfigProvider>
            {deviceMessages?.length === 1 && (
              <div className={"device-data--info"}>
                За выбранный промежуток только 1 сообщение по ТУ.
              </div>
            )}
            {deviceMessages?.length === 0 && (
              <div className={"device-data--info"}>
                За выбранный промежуток нет сообщение по ТУ.
              </div>
            )}
            {deviceMessages?.length > 1 && (
              <>
                <div className={"chart-container"}>
                  <h3>Расход</h3>
                  <ReactECharts option={getChartOptions()} />
                  <DeviceDataTable
                    messages={deviceMessages}
                    point={props.accountingPoint}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
