import React, { useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import {
  fetchDevicesMessagesAction,
  selectCurrentDeviceMessages,
  selectSuppliersList,
  setDevicesMessagesAction,
} from "../../../store/reducers/app.reducer";
import { useDispatch, useSelector } from "react-redux";
import { DeviceDataTable } from "../../../components/DeviceDataTable";
import moment from "moment";
import _ from "lodash";

export const HistoryModal = ({
  visible,
  setVisible,
  point,
  setSelectedPoint,
  supplier,
}: {
  visible: boolean;
  setVisible: Function;
  point: any;
  setSelectedPoint: Function;
  supplier: any;
}) => {
  const dispatch = useDispatch();
  const deviceMessages = useSelector(selectCurrentDeviceMessages);
  const suppliersList = useSelector(selectSuppliersList);
  const [isLoading, setIsLoading] = useState(true);
  const [historyMessages, setHistoryMessages] = useState<any[]>([]);
  const [datePeriodStart, setDatePeriodStart] = useState(moment());
  const [currentSupplier, setCurrentSupplier] = useState<any | null>(null);
  const [datePeriodEnd, setDatePeriodEnd] = useState(
    moment().subtract(1, "year")
  );

  useEffect(() => {
    const sup = _.findLast(suppliersList, { _id: supplier });
    setCurrentSupplier(sup);
  }, [supplier]);

  useEffect(() => {
    if (visible && point) {
      dispatch(
        fetchDevicesMessagesAction(
          datePeriodStart,
          datePeriodEnd,
          [1],
          point._id,
          () => {
            setIsLoading(false);
          }
        )
      );
    }
  }, [point]);

  useEffect(() => {
    setHistoryMessages(_getGroupedMessages());
  }, [deviceMessages]);

  const handleCancel = () => {
    setVisible(false);
    setSelectedPoint(null);
    dispatch(setDevicesMessagesAction(null));
  };
  const _getGroupedMessages = () => {
    let messages:any = _.cloneDeep(deviceMessages);
    let result: any[] = [];
    if (messages == null || messages.length === 0) {
      return [];
    }
    let startPeriod = moment(messages[0]["datetime"] * 1000);
    let stopPeriod = moment();
    for (let year = startPeriod.year(); year <= stopPeriod.year(); year++) {
      for (let month = 0; month <= 11; month++) {
        const startPeriodMessage = moment()
          .year(year)
          .month(month)
          .date(1)
          .hour(0)
          .minutes(0)
          .seconds(0);
        const stopPeriodMessage = moment()
          .year(year)
          .month(month)
          .date(
            currentSupplier && currentSupplier?.report_date != null
              ? currentSupplier.report_date
              : 25
          )
          .hour(23)
          .minutes(59)
          .seconds(59);
        let message = _.findLast(
          messages,
          (m) =>
            m["datetime"] <= stopPeriodMessage.unix() &&
            m["datetime"] >= startPeriodMessage.unix()
        );
        if (message != null) {
          result.push(message);
        }
      }
    }
    return result;
  };
  return (
    <Modal
      width={"80vw"}
      title={`История показаний по ТУ ${point?.name}`}
      visible={visible}
      onCancel={handleCancel}
      footer={[]}
    >
      {point && !isLoading && (
        <DeviceDataTable messages={historyMessages} point={point} />
      )}
      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <Spin size={"large"} wrapperClassName={"spin--wrapper"} />
        </div>
      )}
    </Modal>
  );
};
