import React, { useState }  from "react";
import { loginFormInitialValues } from "../utils/initialValues";
import { loginFormValidation } from "../utils/formValidations";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import "../styles/common.scss";
import { authUser } from "../store/reducers/user.reducer";
import { Form, Alert, Button, Input, Checkbox } from "antd";
import loginLogoBlue from "../assets/logo_blue.svg";
import googlePlayBadge from "../assets/google-play-badge.png";
import { setIsLoading } from "../store/reducers/app.reducer";
import { apiClient } from "../services/api-client";


export function Login() {
  const [form] = Form.useForm();
  const localStorageURL = localStorage.getItem('backendUrl');
  const [invisibleInput, setInvisibleInput] = useState( (localStorageURL) ? true : false);

  const dispatch = useDispatch();

  function onChange(e: any) :void {
      setInvisibleInput(e.target.checked)
      if(!e.target.checked){
        localStorage.removeItem('backendUrl');
      }
  }
  
  const onLogin = async (values: { login: string; password: string}) => {
    dispatch(setIsLoading(true));
    dispatch(authUser(values, () => setIsLoading(false)));
  };
  return (
    <div className={"login-page"}>
      <div className={"login-form"}>
        <div className={"login-logo"}>
          <img alt={"ЛК абонента"} src={loginLogoBlue} width={"285px"} />
        </div>
        <Formik
          initialValues={loginFormInitialValues}
          validate={(values) => loginFormValidation(values)}
          onSubmit={async (values, { setSubmitting }) => {
            localStorage.setItem("backendUrl", values.server);
            apiClient.setBackendUrl(values.server);
            await onLogin(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <>
            <Form
              layout={'vertical'}
              form={form}
            >
              <Form.Item label="Логин">
              <Input 
              type="login"
                name="login"
                placeholder={"Введите Ваш логин"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.login}
              />
              </Form.Item>
        <Form.Item label="Пароль">
        <Input
              className="form-inputs__field"
                type="password"
                name="password"
                placeholder={"Введите Ваш пароль"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />        </Form.Item>
              <div className='form-inputs__button'>
              <p>
            <Checkbox style={{ float: 'left'}} onChange={onChange} checked={invisibleInput}>Указать адрес сервера</Checkbox>
            </p>
          {invisibleInput && 
          <Input
                className="form-inputs__field"
                style={{ margin: '10px 0' }}
                  type="text"
                  name="server"
                  placeholder={"Адрес сервера"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.server}
                />
          }
          <div>
        <Button className="form-button"
                type="primary"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                Войти
        </Button>      
        </div>
            </div>

              {(errors.login || errors.password) && (
                <div className={"login-errors"}>
                  {errors.login && touched.login && (
                    <Alert message={errors.login} type="error" showIcon />
                  )}
                  {errors.password && touched.password && (
                    <Alert message={errors.password} type="error" showIcon />
                  )}
                </div>
              )}

              </Form>
              <a target="blank" href="https://play.google.com/store/apps/details?id=ru.cabinet.abonenta.personal_cabinet"><img height={"40px"} src={googlePlayBadge} alt={"Приложение Google play"}/></a>
              </>
          )}
        </Formik>
      </div>
    </div>
  );
}
