import React from "react";
import { List } from "antd";
import { PointRow } from "./PointRow";

export const PointList = ({
  points,
  supplier,
  setDataModalVisible,
  setSelectedPoint,
  setSelectedSupplier,
  setHistoryModalVisible,
  setDeliveryMessageModalVisible,
}: {
  points: any;
  supplier: any;
  setDataModalVisible: Function;
  setSelectedPoint: Function;
  setSelectedSupplier: Function;
  setHistoryModalVisible: Function;
  setDeliveryMessageModalVisible: Function;
}) => {


  function getExtraColumns(points: any[]) {
    if(points.some(point => point.resource_type_id === 3)){
      return(
        <>
          <div>Состояние реле</div>
          <div>Лимит мгновенной мощности (Ватт)</div>
        </>
        );
    }else{
      return null;
    }
    }

  return (
    <List
      size="small"
      header={
        <div className={"dashboard-header-row"}>
          <div />
          <div>Название</div>
          <div>Серийный номер</div>
          <div>Дата показаний</div>
          <div>Показания</div>
          <div>Дата поверки</div>
          {getExtraColumns(points)}
          <div />
        </div>
      }
      dataSource={points}
      renderItem={(item: any) => (
        <List.Item>
          <PointRow
            setHistoryModalVisible={setHistoryModalVisible}
            point={item}
            setSelectedSupplier={setSelectedSupplier}
            setSelectedPoint={setSelectedPoint}
            setDataModalVisible={setDataModalVisible}
            setDeliveryMessageModalVisible={setDeliveryMessageModalVisible}
          />
        </List.Item>
      )}
    />
  );
};
