import React, {useEffect, useState} from "react";
import hotWater from "../../../assets/icons/devices/hot-water.svg";
import coldWater from "../../../assets/icons/devices/cold-water.svg";
import electro from "../../../assets/icons/devices/electro.svg";
import heat from "../../../assets/icons/devices/heat.svg";
import gas from "../../../assets/icons/devices/gas.svg";
import substance from "../../../assets/icons/devices/substance.svg";
import greenCircle from "../../../assets/icons/green-circle.svg";
import redCircle from "../../../assets/icons/red-circle.svg";
import "../../../styles/pointRow.scss";
import _ from "lodash";
import {getLocalMomentDate} from "../../../types/utils";
import {useSelector} from "react-redux";
import {selectDevicesList} from "../../../store/reducers/app.reducer";
import {Button, Tooltip} from "antd";
import {
    CalculatorOutlined,
    HistoryOutlined,
    LineChartOutlined,
} from "@ant-design/icons";

export const PointRow = ({
                             point,
                             setDataModalVisible,
                             setSelectedPoint,
                             setSelectedSupplier,
                             setHistoryModalVisible,
                             setDeliveryMessageModalVisible,
                         }: {
    point: any;
    setDataModalVisible: Function;
    setSelectedPoint: Function;
    setSelectedSupplier: Function;
    setHistoryModalVisible: Function;
    setDeliveryMessageModalVisible: Function;
}) => {
    const [currentDevice, setCurrentDevice] = useState<any>(null);
    const devicesList = useSelector(selectDevicesList);

    useEffect(() => {
        setCurrentDevice(_.find(devicesList, {id: point.device_id}));
    }, [point, devicesList]);

    const getSerialNumber = () => {
        return currentDevice?.attributes?.device_serial_number || "-";
    };
    const getVerificationDate = () => {
        return (
            (currentDevice?.attributes?.date_calibration_device &&
                getLocalMomentDate(
                    currentDevice?.attributes?.date_calibration_device
                ).format("DD.MM.YYYY")) ||
            null
        );
    };

    const getRelayStatus = () => {
        if (currentDevice?.status?.power_switch) {
            return (
                <div className='relay-status__center'>
                    <img alt={"Зеленый круг"} width="15px" height="15px" src={greenCircle}/>
                </div>
            );
        } else if (currentDevice?.status?.power_switch === 0) {
            return (
                <div className='relay-status__center'>
                    <img alt={"Красный круг"} width="15px" height="15px" src={redCircle}/>
                </div>
            );
        }
        // else{
        //   return (
        //     null
        //   );
        // }
    };

    const getPowerLimit = () => {
        return (
            (currentDevice?.status?.last_set_limit || null)
        );
    };

    const getMessageDate = () => {
        return (
            (currentDevice?.last_message?.datetime &&
                getLocalMomentDate(currentDevice?.last_message?.datetime).format(
                    "DD.MM.YYYY"
                )) ||
            null
        );
    };

    const getUnits = (resourceTypeId: number) => {
        switch (resourceTypeId) {
            case 1:
                return " м³";
            case 2:
                return " м³";
            case 3:
                return " кВт\\ч";
            case 4:
                return " ГКал";
            case 5:
                return " м³";
            case 6:
                return "";
        }
    };
    const getExpandIcon = (resourceTypeId: number) => {
        switch (resourceTypeId) {
            case 1:
                return <img alt={""} width="24px" height="24px" src={hotWater}/>;
            case 2:
                return <img alt={""} width="24px" height="24px" src={coldWater}/>;
            case 3:
                return <img alt={""} width="24px" height="24px" src={electro}/>;
            case 4:
                return <img alt={""} width="24px" height="24px" src={heat}/>;
            case 5:
                return <img alt={""} width="24px" height="24px" src={gas}/>;
            case 6:
                return <img alt={""} width="24px" height="24px" src={substance}/>;
        }
    };

    function getLastData(point: any) {
        const device = point.device_id
            ? _.find(devicesList, {id: point.device_id})
            : null;
        if (point.resource_type_id === 3) {
            if (point?.tariff_type === 1 || !point?.tariff_type) {
                return (
                    <div>
                        T<sub>о</sub>
                        {": "}
                        {(device?.last_message?.tariff1 &&
                                device?.last_message?.tariff1.toFixed(3) +
                                getUnits(point.resource_type_id)) ||
                            "-"}
                    </div>
                );
            } else {
                let result = [
                    <div key={1}>
                        T<sub>о</sub>
                        {": "}
                        {(device?.last_message?.tariff1 &&
                                device?.last_message?.tariff1.toFixed(3) +
                                getUnits(point.resource_type_id)) ||
                            "-"}
                    </div>,
                ];
                for (let j = 2; j <= point?.tariff_type + 1; j++) {
                    result.push(
                        <div key={j}>
                            T<sub>{j - 1}</sub>
                            {": "}
                            {(device &&
                                    device?.last_message &&
                                    device?.last_message.hasOwnProperty(`tariff${j}`) &&
                                    device?.last_message[`tariff${j}`] &&
                                    device?.last_message[`tariff${j}`].toFixed(3) +
                                    getUnits(point.resource_type_id)) ||
                                "-"}
                        </div>
                    );
                }
                return result;
            }
        } else if (point.resource_type_id === 4) {
            return (
                <div>
                    Показания
                    {": "}
                    {(device?.last_message?.tariff1 &&
                            device?.last_message?.tariff1.toFixed(3) +
                            getUnits(point.resource_type_id)) ||
                        "-"}
                </div>
            );
        } else {
            return (
                <div>
                    Показания:{" "}
                    {(device?.last_message?.in1 &&
                            device?.last_message?.in1.toFixed(3) +
                            getUnits(point.resource_type_id)) ||
                        "-"}
                </div>
            );
        }
    }

    function getItemsForExtraColumns(point: any) {
        if (point.resource_type_id === 3) {
            return (
                <>
                    <div>{getRelayStatus()}</div>
                    <div>{getPowerLimit()}</div>
                </>
            );
        } else {
            return <>
                <div></div>
                <div></div>
            </>
        }
    }

    return (
        <div className={"dashboard-row--head"} key={point._id}>
            <div>{getExpandIcon(point.resource_type_id)}</div>
            <div>{point.name}</div>
            <div>{getSerialNumber()}</div>
            <div>{getMessageDate()}</div>
            <div>{getLastData(point)}</div>
            <div>{getVerificationDate()}</div>
            {getItemsForExtraColumns(point)}
            <div className={"dashboard-row--head-actions"}>
                <Tooltip title="История показаний">
                    <Button
                        type="primary"
                        shape="circle"
                        onClick={() => {
                            setSelectedSupplier(point.supplier_id);
                            setSelectedPoint(point);
                            setHistoryModalVisible(true);
                        }}
                        icon={<HistoryOutlined/>}
                    />
                </Tooltip>
                <Tooltip title="Детализация">
                    <Button
                        type="primary"
                        shape="circle"
                        onClick={() => {
                            setSelectedPoint(point);
                            setDataModalVisible(true);
                        }}
                        icon={<LineChartOutlined/>}
                    />
                </Tooltip>
                {currentDevice?.deviceID &&
                    currentDevice?.deviceID.indexOf("virtual_") !== -1 && (
                        <Tooltip title="Подать показания вручную">
                            <Button
                                onClick={() => {
                                    setSelectedPoint(point);
                                    setDeliveryMessageModalVisible(true);
                                }}
                                type="primary"
                                shape="circle"
                                icon={<CalculatorOutlined/>}
                            />
                        </Tooltip>
                    )}
            </div>
        </div>
    );
};
