import React, {useCallback, useEffect, useState} from "react";
import {Card, Collapse, Select} from "antd";
import "../../styles/dashboard.scss";
import {useSelector} from "react-redux";
import {
    selectAccountingPointList,
    selectObjectsTree,
    selectSuppliersList,
} from "../../store/reducers/app.reducer";
import _ from "lodash";
import {PointList} from "./components/PointList";
import {DataModal} from "./components/DataModal";
import {HistoryModal} from "./components/HistoryModal";
import {DeliveryMessageModal} from "./components/DeliveryMessageModal";

const {Option} = Select;
const {Panel} = Collapse;

export function Dashboard() {
    const accountingPointsList = useSelector(selectAccountingPointList);
    const objectsTree = useSelector(selectObjectsTree);
    const [
        filteredAccountingPointsList,
        setFilteredAccountingPointsList,
    ] = useState<any>([]);
    const [selectedPoint, setSelectedPoint] = useState<any>();
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [dataModalVisible, setDataModalVisible] = useState(false);
    const [
        deliveryMessageModalVisible,
        setDeliveryMessageModalVisible,
    ] = useState(false);
    const [historyModalVisible, setHistoryModalVisible] = useState(false);
    const [preparedObjects, setPreparedObjects] = useState<any>([]);
    const suppliersList = useSelector(selectSuppliersList);

    useEffect(() => {
        setFilteredAccountingPointsList(accountingPointsList);
    }, [accountingPointsList]);

    const getFullNameOfObject = useCallback(
        (objectId: string) => {
            if (objectsTree.length === 0 || objectId === null) {
                return;
            }
            const currentObject = _.find(objectsTree, {_id: objectId});
            const treeObject: any[] = [];
            if (currentObject) {
                let parent = currentObject;
                while (parent.parentId) {
                    const findedObject = _.find(objectsTree, {_id: parent.parentId});
                    if (findedObject) {
                        treeObject.push(findedObject);
                        parent = findedObject;
                    } else {
                        break;
                    }
                }
                return (
                    treeObject
                        .map((o) => o.name)
                        .reverse()
                        .join(" > ") +
                    (treeObject.length > 0 ? " > " : "") +
                    currentObject.name
                );
            } else {
                return currentObject.name;
            }
        },
        [objectsTree]
    );
    const getPreparedObjects = useCallback(() => {
        const pointsObjectsIds = _.uniq(
            accountingPointsList.map((p) => p.parent_id)
        ).filter((oId) => oId !== null);
        return pointsObjectsIds.map((oId) => {
            return {objectId: oId, name: getFullNameOfObject(oId)};
        });
    }, [accountingPointsList, getFullNameOfObject]);

    useEffect(() => {
        const preparedObjects = getPreparedObjects();
        setPreparedObjects(_.uniq(preparedObjects) || []);
    }, [objectsTree, accountingPointsList, getPreparedObjects]);

    const handleChangeObject = (value: any) => {
        if (value === "all") {
            setFilteredAccountingPointsList(accountingPointsList);
        } else {
            setFilteredAccountingPointsList(
                accountingPointsList.filter((p) => p.parent_id === value) || []
            );
        }
    };

    return (
        <div className={"dashboard-container"}>
            <div className={"object--select"}>
                <h3>Объект: </h3>
                <Select
                    className={"object--filter"}
                    defaultValue="all"
                    style={{width: 360}}
                    onChange={handleChangeObject}
                >
                    <Option value="all">Все объекты</Option>
                    {preparedObjects.map((o: any, index: number) => {
                        return (
                            <Option key={index} value={o.objectId}>
                                {o.name}
                            </Option>
                        );
                    })}
                </Select>
            </div>
            {/*<h3>Точки учета</h3>*/}
            {filteredAccountingPointsList.filter(
                (point: { supplier_id: null }) => point.supplier_id === null
            ).length > 0 && (
                <Card title="Нет поставщика">
                    <PointList
                        setDeliveryMessageModalVisible={setDeliveryMessageModalVisible}
                        setHistoryModalVisible={setHistoryModalVisible}
                        setSelectedSupplier={setSelectedSupplier}
                        setSelectedPoint={setSelectedPoint}
                        setDataModalVisible={setDataModalVisible}
                        points={filteredAccountingPointsList
                            .filter(
                                (point: { supplier_id: null }) => point.supplier_id === null
                            )
                            .sort(
                                (a: any, b: any) => a.resource_type_id - b.resource_type_id
                            )}
                        supplier={null}
                    />
                </Card>
            )}
            {suppliersList.map(
                (supplier) =>
                    filteredAccountingPointsList.filter(
                        (point: { supplier_id: null }) => point.supplier_id === supplier._id
                    ).length > 0 && (
                        <Card title={supplier.name} extra={<div>Отчетная
                            дата: {supplier?.report_date ? `${supplier.report_date} число месяца` : 'не указана'}</div>}>
                            <PointList
                                setDeliveryMessageModalVisible={setDeliveryMessageModalVisible}
                                setHistoryModalVisible={setHistoryModalVisible}
                                setSelectedSupplier={setSelectedSupplier}
                                setSelectedPoint={setSelectedPoint}
                                setDataModalVisible={setDataModalVisible}
                                points={filteredAccountingPointsList
                                    .filter(
                                        (point: { supplier_id: null }) =>
                                            point.supplier_id === supplier._id
                                    )
                                    .sort(
                                        (a: any, b: any) => a.resource_type_id - b.resource_type_id
                                    )}
                                supplier={supplier}
                            />
                        </Card>
                    )
            )}
            {selectedPoint && <DataModal
                setSelectedPoint={setSelectedPoint}
                visible={dataModalVisible}
                setVisible={setDataModalVisible}
                point={selectedPoint}
            />}
            {selectedPoint && <HistoryModal
                supplier={selectedSupplier}
                setSelectedPoint={setSelectedPoint}
                visible={historyModalVisible}
                setVisible={setHistoryModalVisible}
                point={selectedPoint}
            />}
            {selectedPoint && <DeliveryMessageModal
                visible={deliveryMessageModalVisible}
                setVisible={setDeliveryMessageModalVisible}
                point={selectedPoint}
                setSelectedPoint={setSelectedPoint}
            />}
            {/* <h3>Точки учета</h3>
            <div className={"dashboard-header-row"}>
                <div/>
                <div>Название</div>
                <div>Серийный номер</div>
                <div>Дата снятия</div>
                <div>Показания</div>
                <div>Дата поверки</div>
                <div>Поставщик</div>
            </div>
            <Collapse accordion onChange={(e) => collapsePoint(e)}>
                {filteredAccountingPointsList.map((point: any, index: number) => (
                    <Panel
                        showArrow={false}
                        key={index}
                        header={
                            <div className={"dashboard-row--head"}>
                                <div>{getExpandIcon(point.resource_type_id)}</div>
                                <div>{point.name}</div>
                                <div>{getSerialNumber(point.device_id)}</div>
                                <div>{getMessageDate(point.device_id)}</div>
                                <div>
                                    {getLastData(point)}
                                </div>
                                <div>{getVerificationDate(point.device_id)}</div>
                                <div>{getSupplierName(point)}</div>
                            </div>
                        }
                    >
                        {collapsedPoint?._id === point._id && (
                            <DeviceData
                                accountingPoint={point}
                                device={_.find(devicesList, {id: point.device_id})}
                            />
                        )}
                    </Panel>
                ))}
            </Collapse>*/}
        </div>
    );
}
