import React, { useEffect, useState } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import {
  authCurrentUser,
  selectAuthUser,
  userLogout,
} from "./store/reducers/user.reducer";
import { Login } from "./pages/Login";
import "antd/dist/antd.css";
import "./styles/common.scss";
import { Button, Layout, Menu, Spin } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { Route, Switch } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { apiClient } from "./services/api-client";
import { selectLoadingState, setIsLoading } from "./store/reducers/app.reducer";

function App() {
  const currentUser = useSelector(selectAuthUser);
  const isLoading = useSelector(selectLoadingState);
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const { Header, Content, Footer, Sider } = Layout;

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      apiClient.setAuthToken(localStorage.getItem("authToken") as string);
      dispatch(authCurrentUser(localStorage.getItem("authToken")));
    } else {
      dispatch(setIsLoading(false));
    }
  }, [dispatch]);

  const logout = () => {
    dispatch(userLogout());
  };

  if (!currentUser) {
    return (
      <>
        <Spin size="large" spinning={isLoading}>
          <Login />
        </Spin>
      </>
    );
  } else {
    return (
      <Spin size="large" spinning={isLoading}>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider collapsed={collapsed} onCollapse={setCollapsed}>
            <div className="logo-white" />
            <div className={"consumer-info"}>
              Добрый день, {currentUser.name}
            </div>
            <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
              <Menu.Item key="1" icon={<HomeOutlined />}>
                Показания
              </Menu.Item>
            </Menu>
            <Button
              className={"header-exit--button"}
              onClick={logout}
              type="primary"
            >
              Выйти
            </Button>
          </Sider>
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{ padding: 0 }}
            />
            <Content style={{ margin: "0 16px" }}>
              <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
              >
                <Switch>
                  <Route exact path="/" component={Dashboard} />
                </Switch>
              </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>
              КабинетАбонента.РФ ©2021
            </Footer>
          </Layout>
        </Layout>
      </Spin>
    );
  }
}

export default App;
