import {createSlice} from "@reduxjs/toolkit";

export interface CommonState {

}

export const common = createSlice({
    name: "common",
    initialState: {
    },
    reducers: {
    },
});

/*******************************************/
/*export const { setCommonDebtorModalIsOpen } = common.actions;
export const toggleCommonDebtorModalIsOpen = (status: boolean) => (
    dispatch: any
) => {
    dispatch(setCommonDebtorModalIsOpen(status));
};*/

/*******************************************/
/*export const selectCommonDebtorModalIsOpen = (state: RootState) => {
    return state.common.commonDebtorModalIsOpen;
};*/
export default common.reducer;
