import {configureStore} from "@reduxjs/toolkit";
import commonReducer, {CommonState} from "./reducers/common.reducer";
import userReducer, {UserState} from "./reducers/user.reducer";
import appReducer, {AppState} from "./reducers/app.reducer";

export interface RootState {
    app: AppState;
    user: UserState;
    common: CommonState;
}

const store = configureStore({
    reducer: {
        app: appReducer,
        common: commonReducer,
        user: userReducer
    },
});
export default store;
