import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDevicesList,
  setDevicesMessagesAction,
} from "../../../store/reducers/app.reducer";
import { DeviceData } from "../../../components/DeviceData";
import _ from "lodash";

export const DataModal = ({
  visible,
  setVisible,
  point,
  setSelectedPoint,
}: {
  visible: boolean;
  setVisible: Function;
  point: any;
  setSelectedPoint: Function;
}) => {
  const dispatch = useDispatch();

  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const handleCancel = () => {
    setVisible(false);
    setConfirmLoading(false);
    setSelectedPoint(null);
    dispatch(setDevicesMessagesAction(null));
  };
  return (
    <Modal
      width={"80vw"}
      title={`Детализация по ТУ ${point?.name}`}
      visible={visible}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      footer={[]}
    >
      {point && <DeviceData visible={visible} accountingPoint={point} />}
    </Modal>
  );
};
