import {createSlice} from "@reduxjs/toolkit";
import {User} from "../../types/User";
import {RootState} from "../rootReducer";
import {apiClient} from "../../services/api-client";
import {encrypt} from "../../types/utils";
import {
    setAccountingPointListAction,
    setDevicesListAction,
    setIsLoading,
    setObjectsTreeAction,
    setSuppliersListAction,
} from "./app.reducer";
import {message} from "antd";

export interface UserState {
    user: User;
    authToken: string;
    companyTimeZone: number;
}

export const user = createSlice({
    name: "user",
    initialState: {
        user: null,
        authToken: null,
        companyTimeZone: 0,
    },
    reducers: {
        setUser: (state, action): void => {
            state.user = action.payload;
        },
        setAuthToken: (state, action): void => {
            state.authToken = action.payload;
        },
        setCompanyTimeZone: (state, action): void => {
            state.companyTimeZone = action.payload;
        },
    },
});

/*******************************************/
export const {setUser, setCompanyTimeZone} = user.actions;
export const authUser = (data: any, callback?: Function) => (dispatch: any) => {
    const encrypted = encrypt(JSON.stringify(data));
    apiClient.setAuthToken(encrypted);
    apiClient.authUser().then(
        (data: any) => {
            dispatch(setUser(data.consumer_data));
            dispatch(setAccountingPointListAction(data.accounting_points_list));
            dispatch(setCompanyTimeZone(data.company_time_zone));
            dispatch(setSuppliersListAction(data.suppliers_list));
            dispatch(setObjectsTreeAction(data.objects_tree));
            dispatch(setDevicesListAction(data.devices_list));
            localStorage.setItem("authToken", encrypted);
            localStorage.setItem("companyTimeZone", data.company_time_zone);
            dispatch(setIsLoading(false));
            if (callback) {
                callback();
            }
        },
        (error) => {
            message.error(error.response.data.error.data.msg);
            dispatch(setIsLoading(false));
        }
    );
};
export const authCurrentUser = (token: any, callback?: Function) => (
    dispatch: any
) => {
    apiClient.setAuthToken(token);
    apiClient.authUser().then((data: any) => {
        dispatch(setUser(data.consumer_data));
        dispatch(setAccountingPointListAction(data.accounting_points_list));
        dispatch(setCompanyTimeZone(data.company_time_zone));
        dispatch(setSuppliersListAction(data.suppliers_list));
        dispatch(setObjectsTreeAction(data.objects_tree));
        dispatch(setDevicesListAction(data.devices_list));
        localStorage.setItem("authToken", token);
        localStorage.setItem("companyTimeZone", data.company_time_zone);
        dispatch(setIsLoading(false));
        if (callback) {
            callback();
        }
    }, err => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("companyTimeZone");
        alert(err?.response?.data?.error?.data?.msg || 'Произошла ошибка, перезагрузите страницу и попробуйте ещё раз.');
        dispatch(setIsLoading(false));
    });
};
export const userLogout = () => (dispatch: any) => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("companyTimeZone");
    window.location.replace("/");
};
export const setCurrentUser = (user: User | null) => (dispatch: any) => {
    dispatch(setUser(user));
};
/*******************************************/
export const selectAuthUser = (state: RootState) => {
    return state.user.user;
};
export const selectCompanyTimeZone = (state: RootState) => {
    return state.user.companyTimeZone;
};

export default user.reducer;
